<template>
  <section class="content">
    <div class="card card-info">
        <div class="overlay" v-if="loadingContent">
            <h1 style="font-size: 40px">
                Sedang diproses <i class="fas fa-sync-alt fa-spin"></i>
            </h1>
        </div>
        <div class="card-body">
            <div class="row" ref="toolbar">
                <div class="col-9">
                    <div class="row form-horizontal">
                        <div class="col-auto">
                            <button type="button" data-action="update" class="btn btn-info mr-2" :disabled="isDisabled"><i class="fas fa-edit"></i> <span class="d-none d-md-inline-block">Edit</span></button>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-end">
                    <div class="btn-group float-right" ref="toolbar">
                        <button
                        type="button" data-action="read" class="btn btn-default"
                        >
                            <i class="fa fa-sync-alt"></i>
                        </button>
                        <!-- <button type="button" data-action="read" class="btn btn-default"><i class="fa fa-sync-alt"></i> <span class="d-none d-md-inline-block">Refresh</span></button> -->
                        <button
                            type="button"
                            :disabled="downloading"
                            v-on:click="downloadXlsx"
                            data-action="export-xlsx"
                            class="btn btn-default"
                        >
                            <i class="fa fa-download"></i> &nbsp;
                            <span
                                class="d-none d-md-inline-block"
                                >Download</span
                            >
                        </button>
                        <router-link :to="{ path: '/ppob/products/upload' }" class="btn btn-default"> <i class="fa fa-upload"></i> <span class="d-none d-md-inline-block">Upload</span> </router-link>
                    </div>
                    <!-- <div class="btn-group float-right">
                        
                    </div> -->
                </div>
            </div>
            <div class="p-2"></div>
            <div class="table-responsive">
                <table class="table table-hover" ref="tblppobproducts">
                    <thead>
                        <tr>
                            <th>KATEGORI</th>
                            <th>PRODUK</th>
                            <th>KODE PRODUK</th>
                            <th>TAG</th>
                            <th>TIPE</th>
                            <th>HARGA BELI</th>
                            <th>ADMIN PROVIDER</th>
                            <th>HARGA JUAL</th>
                            <th>ADMIN SHIPPER</th>
                            <th>DIBUAT</th>
                            <th>CHANGED ON</th>
                            <th>ACTIVE</th>
                            <th>IS VERIFIED</th>
                            <th>DENOM</th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick"></tbody>
                </table>
            </div>
        </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
              </p>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Nama Produk</label>
                  <input
                    id="product_name"
                    class="form-control"
                    v-model="form.product_name"
                    type="text"
                    required
                    name="product_name"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Kode Produk</label>
                  <input
                    id="code"
                    class="form-control"
                    v-model="form.code"
                    type="text"
                    required
                    name="code"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Harga Beli (Khusus Prabayar)</label>
                  <input
                    id="product_cost"
                    class="form-control"
                    v-model="form.product_cost"
                    type="number"
                    name="product_cost"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Harga Jual</label>
                  <input
                    id="product_price"
                    class="form-control"
                    v-model="form.product_price"
                    type="number"
                    name="product_price"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                    <label class="control-label">Tipe</label>
                    <select v-model="form.type" id="type" class="form-control">
                        <option value="prabayar">Prabayar</option>
                        <option value="pascabayar">Pasca Bayar</option>
                    </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Admin Shipper</label>
                  <input
                    id="admin_fee"
                    class="form-control"
                    v-model="form.admin_fee"
                    type="number"
                    name="admin_fee"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Denom (Khusus PLN)</label>
                  <input
                    id="denom"
                    class="form-control"
                    v-model="form.denom"
                    type="number"
                    name="denom"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 form-group mr-4">
                    <label class="control-label"> </label>
                    <input
                        id="active"
                        v-model="form.active"
                        type="checkbox"
                        name="active"
                    />
                    <label for="active">Active</label>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label"> </label>
                    <input
                        id="is_verified"
                        v-model="form.is_verified"
                        type="checkbox"
                        name="is_verified"
                    />
                    <label for="is_verified">Is Verified</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isSubmit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

export default {
    name: "PpobProducts",
    data() {
        return {
            errors: [],
            method: "",
            roles: "",
            formTitle: "Tambah Data",
            form: {
                type: "",
                product_name: "",
                code: "",
                product_price: 0,
                product_cost: 0,
                admin_fee: 0,
                denom: 0,
                active: [],
                is_verified: [],
            },
            isDisabled: true,
            isSubmit: false,
            downloading: false,
            loadingContent: false,
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
    },
    methods: {
        handleClick(e) {
            if (e.target.matches(".link-role")) {
                this.$router.push({ path: "/permit/" + e.target.dataset.id });
                return false;
            }
        },
        submitForm: function (ev) {
            const e = this.$refs;
            this.isSubmit = true;
            this.form.active = this.form.active === true ? true : false;
            this.form.is_verified =
                this.form.is_verified === true ? true : false;
            var data = Object.keys(this.form)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent(this.form[key])
                )
                .join("&");
            var urlSubmit = "/ppob/product_items";
            if (this.method == "PUT")
                urlSubmit = "/ppob/product_items/" + this.form.id;

            authFetch(urlSubmit, {
                method: this.method,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: data,
            })
                .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    return res.json();
                })
                .then((js) => {
                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details);

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key]);
                            }
                        }
                        this.isSubmit = false;

                        return;
                    }
                    this.isSubmit = false;
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal("hide");
                });

            ev.preventDefault();
        },
        downloadXlsx: function (e) {
            this.downloading = true;
            var filter = {};
            filter.q = $("input[name=q]").val();
            filter.category = $("select[name=category]").val();
            var data = Object.keys(filter)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        "=" +
                        (filter[key] === false
                            ? 0
                            : encodeURIComponent(filter[key]))
                )
                .join("&");
            // console.log(data);
            this.loadingContent = true;
            authFetch("/ppob/products_download", {
                method: "POST",
                body: data,
            })
                .then((response) => {
                    if (response.status === 201) {
                    } else if (response.status === 400) {
                    } else if (response.status === 500) {
                        Swal.fire("Timeout!", ``, "error");
                        return false;
                    }

                    return response.blob();
                })
                .then((blob) => {
                    setTimeout(() => {
                        this.downloading = false;
                    }, 1000);
                    this.loadingContent = false;
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = "data_product_items.xlsx";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tblppobproducts, {
            title: "",
            roles: ["read", "update"],
            toolbarEl: e.toolbar,
            ajax: "/ppob/product_items",
            columns: [
                { data: "product_category" },
                { data: "product_name" },
                { data: "code" },
                { data: "tags" },
                { data: "type" },
                {
                    data: "product_cost",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                    },
                },
                {
                    data: "default_admin_fee",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                    },
                },
                {
                    data: "product_price",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                    },
                },
                {
                    data: "admin_fee",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                    },
                },
                {
                    data: "created_on",
                    render: function (data, type, row, meta) {
                        return !data
                            ? "-"
                            : moment(data).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                {
                    data: "updated_on",
                    render: function (data, type, row, meta) {
                        return !data
                            ? "-"
                            : moment(data).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                {
                    data: "active",
                    sorting: false,
                    render: function (data, type, row, meta) {
                        if (data > 0) {
                            return '<h5 class="text-center"><i class="fa fa-check"></i></h5>';
                        } else {
                            return '<h5 class="text-center"><i class="fa fa-times"></i></h5>';
                        }
                    },
                },
                {
                    data: "is_verified",
                    sorting: false,
                    render: function (data, type, row, meta) {
                        if (data > 0) {
                            return '<h5 class="text-center"><i class="fa fa-check"></i></h5>';
                        } else {
                            return '<h5 class="text-center"><i class="fa fa-times"></i></h5>';
                        }
                    },
                },
                {
                    data: "denom",
                    render: function (data, type, row, meta) {
                        return !data ? 0 : data;
                    },
                },
            ],
            frame: false,
            filterBy: [0, 1, 2, 3, 4],
            processing: true,
            language: {
                processing:
                    '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>',
            },
            initComplete: function () {
                $(".loading-overlay").removeClass("show");
            },
            rowCallback: function (row, data) {},
            rowClick: function (row) {
                if (Object.keys(row.data).length > 0) {
                    self.isDisabled = false;
                }
            },
            drawCallback: function () {
                self.isDisabled = true;
            },
            buttonClick: (evt) => {
                if (evt.role == "read") {
                    self.isDisabled = true;
                } else if (evt.role == "create") {
                    self.form = {};
                    self.method = "POST";
                    self.errors = [];
                    self.formTitle = "Tambah Produk PPOB";
                    $(e.formDialog).modal("show");
                } else if (evt.role == "update" && evt.data) {
                    self.form = evt.data;
                    self.form.active = evt.data.active == 0 ? false : true; //operator d
                    self.form.is_verified =
                        evt.data.is_verified == 0 ? false : true; //operator d
                    self.form.admin_fee =
                        evt.data.admin_fee === null ? 0 : evt.data.admin_fee; //operator d
                    self.form.product_price =
                        evt.data.product_price === null
                            ? 0
                            : evt.data.product_price; //operator d
                    self.form.denom =
                        evt.data.denom === null ? 0 : evt.data.denom; //operator d
                    self.form.type =
                        evt.data.type === null ? "" : evt.data.type; //operator d
                    self.method = "PUT";
                    self.errors = [];
                    self.formTitle = "Edit Produk PPOB";
                    $(e.formDialog).modal("show");
                } else if (evt.role == "delete" && evt.data) {
                    Swal.fire({
                        title: "Hapus Produk PPOB?",
                        icon: "question",
                        denyButtonText: '<i class="fa fa-times"></i> Hapus',
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                    }).then((result) => {
                        if (result.isDenied) {
                            authFetch("/ppob/product_items/" + evt.data.id, {
                                method: "DELETE",
                                body: "id=" + evt.data.id,
                            })
                                .then((res) => {
                                    return res.json();
                                })
                                .then((js) => {
                                    this.table.api().ajax.reload();
                                });
                        }
                    });
                }
            },
        });
    },
};
</script>